@media (min-width: 1024px) {
    #event-title-info p {
        font-size: 2.5rem;
    }

    .event-city-date-wrapper .event-city, .event-date {
        font-size: 2rem;
    }

    #counter-section {
        justify-content: flex-start;
        padding-left: 1rem;        
    }

    .App-header .logo-img {
        max-width: 6rem;
        max-height: 6rem;
    }

    .App-header .circleicon-div svg {
        width: 2rem;
        height: 2rem;
    }

    .counter-values {
        font-size: 2.5rem;
    }

    .counter-values svg {
        font-size: 2.5rem;;
        width: 2.5rem;
        height: 2.5rem;
    }

    .session-controls {
        justify-content: center;
    }

    .buttons-wrapper {
        padding-right: 12%;
    }

    .change-lang-btn p {
        font-size: 3rem;
    }

    .App-header .session-controls button {
        margin-right: 20%;
    }

    .twin-button button svg {
        font-size: 3.2rem;
        width: 3.2rem;
        height: 3.2rem;
    }

    .scan-status-area {
        min-height: 30rem;
    }
    
    .event-load-dialog-title {
        font-size: 2rem;
        /* padding: 2rem 2.5rem; */
      }
      
      .event-load-dialog-list li{
        padding-top: 0.8rem;
        font-size: 2rem;
      }

      .event-load-dialog-list button {
        height: 4rem;
        width: 10rem;
        font-size: 1.5rem; 
      }

    .qr-reader-container .camera-disabled-div svg {
        font-size: 8rem;
        width: 8rem;
        height: 8rem;
    }

    .qr-reader-container .camera-disabled-div span {
        font-size: 2.5rem;
    }

    #validation-error {
        font-size: 2rem;
    }

    .scan-status-area .success {
        font-size: 2rem;
    }

    .scan-status-area .version, .scan-status-area .statistics {
        font-size: 1.5rem;
    }

    .scan-status-area .success .check-icon{
        font-size: 4rem;
    }

    .event-load-dialog-actions button{
        width: 11rem;
        height: 3rem;
        font-size: 1.2rem;
    }

    .ticket-details .ticket-details-grid .desc-title {
        font-size: 2rem;
        padding: 1.2rem;
        width: 25rem;
        min-width: 25rem;
        max-width: 25rem;
    }

    .ticket-details .ticket-details-grid .desc-value {
        font-size: 2rem;
        padding: 1.2rem;
    }

    .ticket-details .ticket-details-grid .ticket-journal-container {
        max-height: 25rem;
    }

    .ticket-actions-container .action-btn-container {
        max-width: unset;
      }

}

@media (min-width: 1024px) and (orientation: landscape) {
    .scanner-working-area .qr-reader-container {
        position: relative;
    }

    .interactive-area-wrapper {
        flex-direction: row;
    }

    .scan-status-area {
        width: 35rem;
        min-width: 35rem;
        max-width: 30%;
    }

    #validation-error {
        flex-grow: 1;
        padding: 3rem 1.5rem;
        display: flex;
        flex-direction: column;
    }

    .info-status-wrapper {
        flex-grow: 1;
        align-items: flex-end;
        display: flex;
    }

    .scan-status-area .success {
        padding: 3rem 1.5rem;
    }

}

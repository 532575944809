* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

#root {
  height: 100%;
}

#page-wrapper {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

.scanner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  overflow: hidden;
}

.interactive-area-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  overflow: hidden;
}


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border-bottom: 0.1rem solid #fff;
  /* overflow: hidden; */
}

.App-header .logo-img {
  width: 100%;
  max-width: 4rem;
  max-height: 4rem;
}

.login-header .logo-img {
  margin-left: 3vw;
  margin-top: 0.6rem;
  width: 5rem;
  height: 5rem;
}

.App-header a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.App-header .circleicon-div {
  display: flex;
  align-self: flex-end;
}

.App-header .circleicon-div svg{
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
}


#counter-section {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#counter-section a {
  display: inline-flex;
  justify-content: center;
}

.emergency-mode {
  color: red;
}

.snackbar-msg{
    font-size: calc(0.8rem + 1.5vh);
    margin-left: 1rem;
}

.network-info {
  width: 100%;
  text-align: center;
}

.counter-values {
  font-size: 1.3rem;
  display: inline-flex;
  align-items: baseline;
}

.counter-values svg {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  align-self: center;
}

.App-header .session-controls button {
  color: inherit;
  margin: 0;
  padding: 0;
  width: 100%;
}

.session-controls-wrapper {
  display: flex;
}

.session-controls {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.twin-button {
  flex-direction: row;
  justify-content: space-between;
}

.twin-button button{
  width: 50%;
  max-width: 3rem;
  display: flex;
  justify-content: center;
}

.twin-button button svg {
  width: 90%;
  height: 90%;
}

.change-lang-btn {
  width: 100%;
}

.change-lang-btn p{
  font-size: 1.5rem;
}

#event-title-info p{
  font-size: 1.5rem;
}

#event-title-info .event-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scanner-working-area {
  background-color: black;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.scanner-working-area .qr-reader-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scanner-working-area .qr-reader-container .qr-reader {
  width: 100% !important;
  height: auto !important;
}

.scanner-working-area .qr-reader-container video{
  object-fit: cover;
}

.scanner-working-area .camera-icon {
  text-align: left;
  padding-left: 4vw;
  padding-top: 4vw;
}

.qr-reader-container .camera-disabled-div {
  align-self: center;
}

.qr-reader-container .camera-disabled-div svg {
  width: calc(4rem + 4vw);
  height: calc(4rem + 4vw);
}

.qr-reader-container .camera-disabled-div span {
  font-size: calc(1rem + 1vw);
}

.scanner-working-area .camera-active button {
  color: #dc0000;
}

.scanner-working-area .camera-inactive button {
  color: inherit;
}

.scanner-working-area .light-icon {
  text-align: right;
  padding-right: 4vw;
  padding-top: 4vw;
}

.scanner-working-area .light-active button {
  color: #C3B330;
}

.scanner-working-area .light-inactive button {
  color: inherit;
}

.scan-status-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 20rem;
}

.scan-status-area .scan-status-notif {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-grow: 1;
}

.scan-status-area .version{
  display: block;
  text-align: right;
  padding-right: 1rem;
  font-weight: 200;
  font-size: 1.3rem;
}

.scan-status-area .statistics{
  display: block;
  text-align: right;
  padding-right: 1rem;
  font-size: 1.3rem;
}

.scan-status-area .ticket-input-ctrl{
  padding-bottom: 1.5rem;
  min-height: 30%;
  justify-content: center;
  align-items: center;
}
.scan-status-area .ticket-input-ctrl .txt-box{
  background-color: #d7d7d7;
  font-size: 1.2rem;
}

.scan-status-area .success {
  background-color: green;
  font-size: 1.5rem;
}

#validation-error {
  background-color: red;
  font-size: 1.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-status-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.scan-status-area .status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.scan-status-area .info span {
  width: 80%;
  font-size: 2rem;
}

#validation-error .check-icon{
  font-size: 2rem;
  margin-right: 1rem;
}

.ticket-details{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
}

.ticket-details .ticket-actions{
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.ticket-details .ticket-details-grid .desc-title{
  text-align: right;
  align-self: flex-start;
  font-weight: 500;
  font-size: 1.4rem;
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  flex-grow: 0;
  padding: 0.4rem;
}

.ticket-details .ticket-details-grid .desc-value{
  text-align: left;
  align-self: baseline;
  font-size: 1.4rem;
  flex-grow: 1;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  padding: 0.4rem;
}

.ticket-details .ticket-details-grid .sec-title{
  text-align: left;
  margin-left: 5vw;
  font-size: calc(0.8rem + 2vw);
  letter-spacing: 0.2em;
  font-weight: 500;
  margin-top: 1rem;
}

.ticket-details .ticket-details-grid input {
  background-color: #d7d7d7;
  border-radius: 0.4rem;
  height: 1.2rem;
  font-size: 1.4rem;
}

.ticket-actions .ticket-actions-container .ticket-actions-row {
  align-content: center;
  padding-top: 2vh;
  justify-content: center;
  padding: 1rem;
}

.ticket-actions .ticket-actions-container button {
  width: 100%;
  background-color: #d7d7d7;
  color: #282c34;
  font-size: calc(1vh + 1vw);
  padding: 0.3rem;
}

.ticket-actions .ticket-actions-container button:disabled, button[disabled]{
  background-color: inherit;
  color: #fff;
  font-style: italic;
  border: 0.1rem solid #fff;
}

.ticket-details .ticket-details-grid .ticket-journal-container{
    overflow: auto;
    width: 100%;
    padding: 1rem 2rem 1rem 1rem;
    text-align: left;
    font-size: 1.3rem;
    flex-grow: 1;
    max-height: 18rem;
    max-width: 100%;
}
.ticket-details .ticket-details-grid .ticket-journal-title{
  align-self: flex-start;
  padding-top: 2rem;
  padding-left: 1rem;
}

.event-load-dialog{
  z-index: 9999;
}
.event-load-dialog-close{
  color: #d7d7d7;
}
.event-load-dialog .MuiPaper-root{
  background-color: #333;
  color: #d7d7d7;
}

.event-load-dialog-title {
  color: #d7d7d7;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 2rem 2.5rem;
}

.event-load-dialog-list li{
  padding: 0;
  color: #d7d7d7;
  font-size: 1.2rem;
}

.event-load-dialog-list button {
  background-color: #d7d7d7;
  color: #282c34;
  height: 3.5rem;
  width: 7.0rem;
  font-size: 0.65rem;
}

.event-load-dialog-actions {
  align-self: center;
}

.event-load-dialog-actions button{
  background-color: #d7d7d7;
  color: #282c34;
  margin-bottom: 1.8rem;
}

.event-load-dialog-content-hints {
  text-align: center;
}

.event-load-dialog-content-hints img{
  width: 90%;
  border: 0.5rem solid #c16a00;
  border-radius: 0.4rem;
}

.event-control-modal-desc {
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.event-control-modal-opt-div { width: 100%; }

.event-control-modal-opt-div .event-control-modal-opt-wrapper { 
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}

.event-control-modal-opt-wrapper .swt-off {
  padding-left: 0.5rem;
  padding-right: 1rem;
}
.event-control-modal-opt-wrapper .swt-on {
  padding-left: 1rem;
  padding-right: 0.5rem;
}

/*
* Login form Component Styles
*/
.login-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-header-wrapper {
  display: inline-flex;
  justify-content: space-between;
  min-width: 32rem;
  max-width: 100%;
  width: 60rem;
  align-items: center;
}

.login-form-input-wrapper {
  min-width: 32rem;
  /* display: flex;
  justify-content: center; */
  max-width: 100%;
  width: 60rem;
}

.login-form {
  background-color: #78213A;
  padding: 4vh 2vw;;
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.6rem 2rem 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 4rem;
  margin-top: 0rem;
  margin-left: 3vw;
  margin-right: 3vw;
  overflow: hidden;
}

.login-form .button-container {
  display: flex;
  justify-content: center;
}
.login-form form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.login-form .list-container {
  display: flex;
}

.login-form .login-error {
  color: red;
  font-size: 1.5rem;
  text-align: center;
}

.login-form .title {
  font-size: 2.5rem;
  margin-bottom: 3vh;
  text-align: center;
}

.login-form .input-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 60%;
  min-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2vh;
}

.login-form .input-container input {
  font-size: 1.5rem;
  background-color: #ddd;
  color: #333;
}

.login-form #form-submit {
  font-size: 1.6rem;
    padding: 1rem 2rem;
    border: 0.1rem solid #888;
    border-radius: 0.4rem;
    background-color: #d7d7d7;
    color: #333;
    margin-top: 1rem;
}

.login-form .password-input {
  background-color: #ddd;
}

.login-info-card {
  margin: 1.3vh 3vw;
}

.login-info-card-title, .login-info-card-body p{
  font-family: monospace;
  
}

.login-info-card-span {
  font-weight: 600;
}

/* CSS Styles for Update App Version Modal */
.update-modal{
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /*Fallback value*/
  background-color: rgba(0, 0, 0, 0.4); /*Preferred value, black with opacity*/
  color:#ddd;
  position: fixed;
  z-index: 999;
  padding-top: 4rem;
  top: 0;
  left: 0;
  font-size: 1.2rem;
}

.update-modal .update-modal-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 30rem;
  max-width: 70rem;
  background-color: #fefefe;
  margin: auto;
  padding: 2em;
  border: 0.1rem solid #888;
  box-sizing: border-box;
  box-shadow: 0 0.1rem 0.6rem rgba(5,27,44,.06),0 0.2rem 3.2rem rgba(5,27,44,.16)!important;
  border-radius: 0.5em;
}

.update-modal-desc-container .close-x {
  color: #aaa;
  float: right;
  font-size: 2.8rem;
  font-weight: bold;
  display: none;
}

.close-x:hover, .close-x:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-closed {
  display: none;
}

.update-modal-desc-container {
  display: flex;
}

.update-modal-desc-container .icon-container {
  display: block;
  background: #5e8de5;
  width: fit-content;
  height: fit-content;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 0.6rem;
  padding-left: 0.6rem;
  border-radius: 0.8rem;
}

.update-modal-desc-container .icon-container img {
  width: 3.5rem;
  transform: rotate(40deg);
}

.update-modal-desc-container p {
  display: block;
  font-size: 1.3rem;
  font-weight: 400;
  color: #051b2c!important;
  padding: 0rem 1.2rem;
  margin: 0;
}

.update-modal-actions {
  display: inline-flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  text-align: right;
}

.update-modal-actions button {
    padding: .75em 1.5em;
    font-size: 1.3em;
    border-radius: 0.25em;
    font-weight: 400;
    box-shadow: unset;
    display: -ms-flexbox;
    background: #5e8de5;
    color: #fff!important;
    transition: 75ms linear;
    border: 0.1rem solid transparent;
    text-align: center;
    vertical-align: middle;
    text-align: right;
    cursor: pointer;
}

#update-modal-cancel-btn {
  color: #5e8de5!important;
  background: #fff!important;
}

#personalize-save-btn-container {
  width: 25%;
  min-width: 25%;
}

.desc-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* CSS Styles for landscape mode up to xxxpx vieport width
  limited by the next breakpoint set at 800px on 'tablet.css'
*/
@media (orientation: landscape) {
  .scanner-working-area .qr-reader-container {
      position: relative;
  }

  .interactive-area-wrapper {
      flex-direction: row;
  }

  .scan-status-area {
      width: 25rem;
      min-width: 25rem;
      max-width: 40%;
  }

  #validation-error {
      flex-grow: 1;
      padding: 1rem 0.5rem;
      display: flex;
      flex-direction: column;
  }

  .info-status-wrapper {
      flex-grow: 1;
      flex-direction: column;
      display: flex;
  }

  .scan-status-area .success {
      padding: 1rem 0.5rem;
  }

  .ticket-details .ticket-details-grid .ticket-journal-container {
    max-height: 8rem;
  }

  #details-journal {
    overflow: scroll;
  }

  .ticket-details .ticket-details-grid .ticket-journal-title {
    padding-top: 0;
  }

  .ticket-actions-container .action-btn-container {
    max-width: 25%;
  }

  .ticket-details .ticket-details-grid .desc-value{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}
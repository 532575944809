/* CSS Styles for portrait mode starting at 600px vieport width*/
@media (min-width: 600px) {
    .logo-data-container {
        display: inline-flex;
    }

    .App-header .circleicon-div {
        align-self: center;
        margin-left: 0.5rem;
    }

    .network-info {
        align-items: center;
        display: inline-flex;
    }

    .counter-values {
        font-size: 1.6rem;
    }

    .counter-values svg {
        font-size: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    #event-title-info {
        display: flex;
        flex-direction: column;
    }

    .event-city-date-wrapper {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .session-controls {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .App-header .session-controls button {
        margin-right: 10%;
    }

    .change-lang-btn p {
        font-size: 2.5rem;
    }

    .scan-status-area .ticket-input-ctrl .txt-box {
        font-size: 1.5rem;
        max-width: 50rem;
    }

    .focus-textbox-icon svg {
        width: 2.5rem;
        height: 2.5rem;
    }

    .twin-button {
        width: 50%;
    }

    .ticket-details {
        margin-top: 3rem;
    }

}

/* CSS Styles for portrait mode starting at 800px vieport width*/
@media (min-width: 800px) {
    #event-title-info p {
        font-size: 2rem;
    }

    .event-city-date-wrapper .event-city, .event-date {
        font-size: 2rem;
    }

    #counter-section {
        justify-content: flex-start;
        padding-left: 1rem;        
    }

    .App-header .logo-img {
        max-width: 5rem;
        max-height: 5rem;
    }

    .App-header .circleicon-div svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    .counter-values {
        font-size: 2rem;
    }

    .counter-values svg {
        width: 1.8rem;
        height: 1.8rem;
    }

    .scan-status-area .ticket-input-ctrl .txt-box {
        font-size: 1.5rem;
        max-width: 50rem;
    }

    .focus-textbox-icon svg {
        width: 2.5rem;
        height: 2.5rem;
    }

    .event-load-dialog-list li {
        padding-bottom: 0;
        padding-top: 0.5rem;
        font-size: 1.5rem;
    }

    .ticket-details .ticket-details-grid .ticket-journal-container {
        max-height: 20rem;
    }

    .ticket-details .ticket-details-grid .desc-title {
        font-size: 1.8rem;
    }

    .ticket-details .ticket-details-grid .desc-value {
        font-size: 1.8rem;
    }

}

/* CSS Styles for landscape mode starting at 600px vieport width*/
@media (min-width: 800px) and (orientation: landscape) {
    .scan-status-area {
        width: 30rem;
        min-width: 30rem;
        max-width: 30%;
    }

    .ticket-details .ticket-details-grid .ticket-journal-container {
        max-height: 10rem;
    }

    .ticket-details .ticket-details-grid .desc-title {
        font-size: 1.5rem;
        width: 25rem;
        min-width: 25rem;
        max-width: 25rem;
    }

    .ticket-details .ticket-details-grid .desc-value {
        font-size: 1.5rem;
    }

    .ticket-details {
        margin-top: 1rem;
    }

    .ticket-actions-container .action-btn-container {
        max-width: 25%;
    }
}






